import logo from '../salvajevinos.png'
import { Link } from 'react-router-dom'
import Authentication from './Authentication'
import { useState } from 'react'

export const NavigationBar = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)

  const toggleClass = () => {
    setMobileMenuOpen(!isMobileMenuOpen)
  }

  return (
    <>
      <nav className="flex items-center justify-between flex-wrap nav-bar-color pl-8 pr-8 pt-4 pb-4"></nav>
    </>
  )
}
