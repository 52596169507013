import Loading from '../components/Loading'
import env from '../env'
import React, { useEffect, useCallback, useReducer, useRef, useState } from 'react'
import { SSE } from 'sse.js'
import { CreateCompletionResponse } from './interfaces'
import ReactMarkdown from 'react-markdown'

const sampleQuestions = [
  'How can I add rate limiting to my api ?',
  'Can I create a custom policy for my business api ?',
  'Is it possible to access an environment variable using custom code policies?',
]

function promptDataReducer(
  state: any[],
  action: {
    index?: number
    answer?: string | undefined
    status?: string
    query?: string | undefined
    type?: 'remove-last-item' | string
  }
) {
  // set a standard state to use later
  let current = [...state]

  if (action.type) {
    switch (action.type) {
      case 'remove-last-item':
        current.pop()
        return [...current]
      default:
        break
    }
  }

  // check that an index is present
  if (action.index === undefined) return [...state]

  if (!current[action.index]) {
    current[action.index] = { query: '', answer: '', status: '' }
  }

  current[action.index].answer = action.answer

  if (action.query) {
    current[action.index].query = action.query
  }
  if (action.status) {
    current[action.index].status = action.status
  }

  return [...current]
}

export function Assistant() {
  const [question, setQuestion] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [answer, setAnswer] = useState<string | undefined>('')
  const [isResponding, setIsResponding] = useState(false)
  const eventSourceRef = useRef<SSE>()
  const [promptIndex, setPromptIndex] = useState(0)
  const [promptData, dispatchPromptData] = useReducer(promptDataReducer, [])

  const handleSubmit = useCallback(async () => {
    setAnswer(undefined)
    dispatchPromptData({ index: promptIndex, answer: undefined, query: undefined })
    setIsResponding(false)
    setIsLoading(true)

    const eventSource = new SSE(`https://salvaje-api.herokuapp.com/v1/zuplo-assistant`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: '*',
        // Authorization: `Bearer ${accessToken}`,
      },
      payload: JSON.stringify({
        query: question,
      }),
    })

    function handleError<T>(err: T) {
      setIsLoading(false)
      setIsResponding(false)
      console.error(err)
    }

    eventSource.addEventListener('error', handleError)
    eventSource.addEventListener('message', (e: any) => {
      try {
        setIsLoading(false)

        if (e.data === '[DONE]') {
          setIsResponding(false)
          setAnswer(undefined)
          setPromptIndex((x) => {
            return x + 1
          })
          return
        }

        setIsResponding(true)

        const completionResponse = JSON.parse(e.data) as CreateCompletionResponse

        const themChoices = completionResponse.choices

        //console.log('choices ==> ', completionResponse.choices)
        const text = themChoices[0].delta.content ?? ''

        // console.log(text)
        setAnswer((answer) => {
          const currentAnswer = answer ?? ''

          dispatchPromptData({
            index: promptIndex,
            answer: currentAnswer + text,
            query: question,
          })

          return (answer ?? '') + text
        })
      } catch (err) {
        handleError(err)
      }
    })

    eventSource.stream()

    eventSourceRef.current = eventSource

    setIsLoading(true)
  }, [promptIndex, question])

  function handleResetPrompt() {
    eventSourceRef.current?.close()
    eventSourceRef.current = undefined
    //setSearch('')
    setAnswer(undefined)
    setIsResponding(false)
  }

  useEffect(() => {
    if (question) {
      handleSubmit()
    }
  }, [])

  return (
    <div className="flex flex-col items-center justify-center h-screen" onClick={(e) => e.stopPropagation()}>
      <div className="m-10 grid grid-cols-5 gap-1 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="col-span-5">
          <h2>Docs AI</h2>
        </div>

        {isLoading && <Loading />}

        {promptData.length === 0 && (
          <div className="col-span-5 h-24">
            <h3>Question examples</h3>
            <ul>
              {sampleQuestions.map((question) => {
                const key = question.replace(/\s+/g, '_')
                return <li key={key}>{question}</li>
              })}
            </ul>
          </div>
        )}

        {promptData.map((prompt, i) => {
          if (!prompt.query) return <></>

          return (
            <>
              {prompt.query && (
                <div className="col-span-5 mx-4 [overflow-anchor:none] mb-6">
                  <div className="prose text-scale-1000">{prompt.query}</div>
                </div>
              )}

              <div className="col-span-2" />
              <div className="col-span-3 px-4 [overflow-anchor:none] mb-6">
                <div>
                  <ReactMarkdown>{prompt.answer}</ReactMarkdown>
                </div>
              </div>
            </>
          )
        })}

        <div className="col-span-4 mt-4">
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            value={question}
            placeholder={isLoading || isResponding ? 'Waiting on response ...' : 'Ask any question about Zuplo docs'}
            onChange={(e) => setQuestion(e.target.value)}
          />
        </div>

        <div className="col-span-1 mt-4">
          <div className="flex items-center justify-center">
            <button
              type="button"
              onClick={handleSubmit}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              disabled={isLoading || isResponding}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
