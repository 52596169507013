import fblogo from '../resources/fb-icon.png'
import instagramlogo from '../resources/instagram-icon.png'

export function Footer() {
  return (
    <>
      <div className="sticky top-[100vh] footer-color px-8 pt-10 pb-6 footer-container">
        <div className="px-2 text-left"></div>
        <div className="pt-6 pb-0 text-sm text-center"></div>
      </div>
    </>
  )
}
