import React from 'react'
import './App.css'
import { NavigationBar } from './components/NavigationBar'
import { Route, Routes } from 'react-router-dom'
import { Footer } from './components/Footer'
import useLoadUserState from './userState/useLoadUserState'
import Loading from './components/Loading'
import { Assistant } from './pages/Assistant'

function App() {
  const { isLoading, isAuthenticated } = useLoadUserState()

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="App min-h-screen">
      <NavigationBar isAuthenticated={isAuthenticated} />
      <Routes>
        <Route path="/" element={<Assistant />} />
      </Routes>
      <Footer />
    </div>
  )
}

export default App
